<template>
  <section class="section">
    <div class="pageloader" :class="{ 'is-active': inProgress }"></div>
    <h2 class="service subtitle">Праздничные/выходные дни</h2>
    Выберите год:
    <b-select v-model="selectedYear" @input="setCalendarDays">
      <option v-for="item in years" :value="item" :key="item">
        {{ item }}
      </option>
    </b-select>
    <template v-if="this.items.length > 0">
      <div style="margin-bottom: 100px">
        <template v-for="(date, index) in dates">
          <div class="datepicker-block" :key="'datepicker_' + index">
            <b-datepicker
              :inline="true"
              v-model="dates[index]"
              :events="calendarEvents"
              indicators="bars"
              @input="onInput"
            >
            </b-datepicker>
          </div>
        </template>
      </div>
    </template>
  </section>
</template>

<script>
import moment from "moment";

export default {
  name: "CalendarDays",
  data() {
    return {
      inProgress: false,
      dates: [],
      items: [],
      selectedYear: parseInt(moment().format("YYYY")),
      apiResource: this.$apiResource.make("CalendarDays")
    };
  },
  created() {
    this.setCalendarDays();
  },
  computed: {
    years() {
      let year = parseInt(moment().format("YYYY")) - 5;
      let result = [];
      do {
        result.push(year);
        year++;
      } while (result.length <= 10);
      return result;
    },
    calendarEvents() {
      return this.items.map(item => {
        return {
          date: moment(item["day"]).toDate(),
          type: item["is_holiday"] ? "is-danger" : "is-success"
        };
      });
    }
  },
  methods: {
    onInput(value) {
      const dateString = moment(value).format("DD-MM-YYYY");
      const dateItem = this.items.filter(item => {
        return moment(item["day"]).format("DD-MM-YYYY") === dateString;
      })[0];
      const message =
        "Вы действительно хотите сделать " +
        dateString +
        (dateItem["is_holiday"]
          ? " рабочим днём"
          : " выходным/праздничным днём") +
        "?";

      if (confirm(message)) {
        this.changeDay(dateString, !dateItem["is_holiday"]);
      }
    },
    changeDay(day, isHoliday) {
      this.inProgress = true;
      this.apiResource
        .store({
          day,
          is_holiday: isHoliday
        })
        .then(() => {
          this.setCalendarDays();
        });
    },
    setDates() {
      this.dates = [];
      const currentYear = this.selectedYear;
      let currentDate = moment(this.selectedYear, "YYYY").startOf("year");
      do {
        this.dates.push(currentDate.toDate());
        currentDate.add(1, "month");
      } while (parseInt(currentDate.format("Y")) === currentYear);
    },
    setCalendarDays() {
      this.inProgress = true;
      this.apiResource.list({ year: this.selectedYear }).then(response => {
        this.items = response["data"];
        this.setDates();
        this.inProgress = false;
      });
    }
  }
};
</script>

<style scoped>
.datepicker-block {
  display: inline-block;
  margin: 5px;
  width: 20%;
  min-height: 350px;
  min-width: 350px;
}
</style>
